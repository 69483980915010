/**
 * Performs geocoding for a given address using Yandex Maps API and extracts detailed address information.
 * If the address is found, returns an object with coordinates and various address details.
 * Returns null if no geo object is found for the provided address.
 * @param {string} address - The address string to be geocoded.
 * @returns {Promise<object|null>} A promise that resolves to an object containing address information (coordinates, country code, country name, full address line, and town name), or null if the address cannot be geocoded.
 */
export const getAddressInfo = address => {
    return ymaps.geocode(address).then(async res => {
        const geoObject = res.geoObjects.get(0);

        if (geoObject === undefined) {
            return null;
        }

        const metaDataProperty = geoObject.properties.get('metaDataProperty');
        const coords = geoObject.geometry.getCoordinates();
        const code = metaDataProperty?.GeocoderMetaData.AddressDetails?.Country?.CountryNameCode || '';
        const countryName = metaDataProperty?.GeocoderMetaData.AddressDetails?.Country?.CountryName || '';
        const addressLine = metaDataProperty?.GeocoderMetaData.AddressDetails?.Country?.AddressLine || '';
        const town =
            metaDataProperty?.GeocoderMetaData?.AddressDetails?.Country?.AdministrativeArea?.SubAdministrativeArea?.Locality?.LocalityName ||
            metaDataProperty?.GeocoderMetaData?.AddressDetails?.Country?.AdministrativeArea?.Locality?.LocalityName ||
            '';

        return {
            coords,
            code,
            countryName,
            addressLine,
            town,
        };
    });
};
